<template>
<a-card :bordered="false" class="card-area">
    <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
            :scroll="{ x: 1000 }">
    
    </a-table>
</a-card>
</template>
<script>
export default{
    name: "refundList",
    computed:{
        headers() {
            return {
                Authentication: this.$store.state.account.token,
            };
        },
        columns(){
            return[
            {
                title: "ID",
                width: 50,
                align: "center",
                dataIndex: "id",
            }, 
            ];
        }
    }
}
</script>